@import 'variables';

//noinspection CssUnknownTarget
@import url(https://fonts.googleapis.com/css?family=Lato:400,300italic,300,100italic,100,400italic,700,700italic,900,900italic);

html {
  font-family: $body-font-face, sans-serif;
  font-size: 12pt;
  line-height: 1.6;
  background-color: $secondary;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

input {
  font-family: $body-font-face;
  font-size: 12pt;
  padding: 2pt 4pt;
}

section.info,section.warn,section.alert {
  font-size: $base-font-size * 1.5;
}

section.info {
  color: $info-colour;
}

section.warn {
  color: $warn-colour;
}

section.alert {
  color: $alert-colour;
}

section.actionStatus {
  font-size: 130%;

  &.processing {
    color: #404efa;
  }
  &.complete {
    color: #00da00;
  }
  &.failed {
    color: #FF312E;
  }
}

div.question {
  border: lighten($secondary, 30%) solid 2px;
  border-radius: 5px;
  padding: 5px;
}

button, a[role=button] {
  background-color: white;
  padding: 2pt 8pt;
  font-size: 10pt;
  text-decoration: none;
  color: black;
  border: 1px solid #888;
  border-radius: 3pt;
  margin: auto 14pt;
  font-family: $body-font-face;

  &.dangerous {
    background-color: #ff5a5d;
    font-weight: 500;
  }

  &:disabled {
    border-color: $light-gray;
    color:$light-gray;
  }
}

table {
  width: 100%;
  border: 2px solid #AAA;
  border-collapse: collapse;

  thead {
    tr:last-child {
      border-bottom: 1px solid #AAA;
    }

    th {
      background-color: #EEE;
    }
  }

  tbody {

    tr {
      &:nth-of-type(even) {
        background-color: #F8F8F8;

        th {
          background-color: #EEEEEE;
        }
      }

      th {
        background-color: #F2F2F2;
        text-align: right;
      }
    }
  }

  tfoot {

    tr {
      border-top: 1px solid #AAA;
      background-color: #EEE;

      th {
        text-align: left;
      }
    }
  }

  th, td {
    padding: 0.5em 0.5em 0.5em 0.5em;
  }
}

blockquote {
  margin: 8pt 0;
  padding: 6pt 12pt;
  background-color: lighten($accent, 40%);
}

section.main {
  background-color: $bright-white;
  padding: 0 $regular-size 0pt $regular-size;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  flex: 1;

  footer {
    text-align: right;
  }

  h1 {
    width: 100%;
    font-size: $base-font-size;
    box-sizing: border-box;
    color: $bright-white;
    background: linear-gradient(to right, $medium-gray, $white);
    margin: 0 0 $small-size 0;
    padding: $base-padding/4 $base-padding;
    border-bottom-left-radius: $base-padding;
  }
}

body > footer {
  background-color: $secondary;
  color: $bright-white;
  font-size: 9pt;
  font-weight: 300;
  padding: 0pt $regular-size 0pt $regular-size;
  border-top: $small-size/2 solid $accent;
}

h1,h2,h3,h4,h5 {
  color: $secondary;
  font-weight: normal;
  margin: 0;
}
h1 { font-size: 200%; }
h2 { font-size: 150%; }
h3 { font-size: 120%; }
h4 { font-size: 110%; }
h5 { font-size: 100%; }

form {
  fieldset {
    border: 1px solid lighten($secondary, 50%);
    border-radius: 1em;

    padding: 10pt 18pt 18pt;
    margin: 12pt 0;

    legend {
      color: lighten($secondary, 30%);
    }
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    li {
      position: relative;
      margin-left: 1.75em;
      h2 {
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        background-color: lighten($secondary, 30%);
        color: $bright-white;
        font-size: 12pt;
        margin: 0.75em 0 0 20px;
        padding: 0.1em 1em;
        span.label {
          color: $secondary;
        }
        span.title {
          margin-left: 1em;
        }
      }
    }
  }

  font-size: 12pt;

  .two-list-sorter {
    display: flex;
    align-items: center;

    .list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      select {
        min-width: 15em;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        margin: 0.5em 0.5em 0.5em 0.5em;
        vertical-align: middle;
      }
    }
  }

  .actions {
    text-align: right;
    .reposition {
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 500pt) {
  section.main {
    padding: 0 2pt 0pt 2pt;
  }
}
