@import '../../base/variables';

table.purchasableQuestionnaires {
  td.description {
    overflow: hidden;
    width: 30%;
  }

  button {
    background-color: #4bda43;
  }
}