section#login {
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    max-width: 300pt;
    fieldset {
      display: flex;
      flex-direction: column;
      label {
        margin-top: 4pt;
      }
      section.actions {
        margin: 8pt;
      }
    }
  }
}
