@import 'base/variables';

section#coaches,section#dashboard,section#available-questionnaires,section#profile {
  flex-direction: column;
}

section.main {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 90vh;

  section.screen{
    display:flex;
    flex-direction:row;
    flex-grow: 1;
    height: 80vh;
    .dashboard{
      flex: 1 1 0;

    }
    .screen-item {
      ul,ol { margin: 0; padding: 0; list-style-type: none; }
      display: flex;
      align-items:stretch;
      flex-direction: column;
      overflow-y:scroll;
      padding: $medium-size;

      margin: $tiny-size;

      details{
        padding:10pt;
        word-wrap: break-word;
        cursor: pointer;

        summary{
          border-bottom: 1pt solid #EEEEEE;
          padding: $small-size/2;
            button {
              margin: 0 0 $small-size/2 $small-size/2;
              font-size: 10pt;
              padding: $tiny-size 8pt;
          }
        }
      }
    }
  }
}

ul.details-list {
  display: flex;
  flex-direction: column;
  border-radius: 0 0 $small-size $small-size;
  background-color: #eee;
  ul,ol { margin: 0; padding: 0; list-style-type: none; }

  li {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    padding: $small-size/3 $small-size;
    margin: $small-size 0;
    cursor: pointer;

    &.description,&.actions {
      margin: 0; padding: 0;
    }

    &.actions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;
      align-content: flex-start;
      button {
        margin: 0 0 $small-size/2 $small-size/2;
        font-size: 10pt;
        padding: $tiny-size 8pt;

      }
    }

    ol.overview {
      display: flex;
      flex-direction: column;
      border-bottom: $tiny-size solid $medium-gray;
    }

    ol.details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 80%;
      li {
        margin: 0 4pt;
        padding: 0;
        flex: 1 1 0;
        font-weight: bold;
        label {
          cursor: pointer;
          font-weight: normal;
        }
        span.statusAccepted {
          color: #00da00;
        }
        span.statusOpen {
          color: #7f54ff;
        }
      }
    }

  }
}
ul.group{
  border-radius: 0;
  background-color: $screen-background;
  justify-content: space-between;
  li{
    border-radius: $small-size;
    background-color: #eee;
    width: 150pt;
  }
}

@media screen and (max-width: 800px) {
  html{
    background-color: $screen-background;
  }
  body{
    width:auto;
  }
  footer{
    display:none;
  }
  section.main{
    h1{
      margin: 0;
    }
    padding:0;
    section.screen{
      flex-direction:column;

      .screen-item{
        height: 50vh;
        padding: 0;
        border-bottom: $tiny-size solid $medium-gray;
        margin: 0;
        ul.details-list{
          li{
            padding: 0;
          }
        }
        ol.details {
          li {
            margin: 0 2pt;
          }
        }
        details{
          ::-webkit-details-marker {
            font-size: 4vh;
            padding-right: 10pt;
          }
          ul,ol{
            word-wrap: break-word;

          }
        }
      }
      .dashboard{
        height: 29vh;

      }
    }
  }
}
