@import '../base/variables';

header.site {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $secondary;
  height: 6vh;
  box-shadow: lighten($secondary, 30%) 4pt 4pt 12pt;
  padding: $small-size;

  h1 {
    flex-grow: 1;
    height: 5vh;
    background: url(/images/logo.svg) left center no-repeat;
    margin: 0 0 0 12pt; padding: 0;
    color: $bright-white;

    font-size: 4vh;
    font-weight: 300;
    text-transform: uppercase;
    text-shadow: 1pt 1pt 2pt darken($secondary, 30%);
    text-decoration: inherit;
    overflow-x: hidden;
  }

  nav {
    ol {
      margin: 0 12pt 0 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      li {
        margin-left: 12pt;
        font-size: 12pt;
        a {
          cursor: pointer;
          color: $accent;
          text-decoration: none;
          text-shadow: 1pt 1pt 2pt darken($secondary, 30%)
        }
      }
    }
  }
}

@media only screen and (max-width : 750px) {
  header.site {
    h1{
      display: flex;
      flex-grow: 1;
      font-size: 6vw;
    }

    nav{
      position: relative;
      cursor: pointer;
      line-height: 4vh;

      &::before{
        content:"☰";
        color: $bright-white;
        margin-right: 12pt;
        font-size: 4vh;
      }

      ol {
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;
        background-color: $secondary;
        display: none;

        flex-direction: column;

        li {
          padding: 15px 20px 15px 15px;
          text-align: left;
          z-index: 1;
        }
      }

      &:hover, &:focus {
        ol {
          display: block;
        }
      }
    }
  }
}


