$accent: #C3B259;
$secondary: #5C5453;

$regular-size: 12pt;
$medium-size: 10pt;
$small-size: 6pt;
$tiny-size: 1pt;

$white: #f8f8f8;
$bright-white: #FFFFFF;
$medium-gray: #888888;
$light-gray: #EEEEEE;
$dark: #222222;

$alert-colour: #aa4422;
$warn-colour: #aa9922;
$info-colour: #22aa44;

$body-font-face: "Lato", sans-serif;
$base-font-size: $regular-size;
$base-padding: $base-font-size;

$screen-background: #f8f8f8;
@mixin wrapping-scroller {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ul.details-list {
    flex-direction: row;
    flex-wrap: wrap;

    li {
      margin: $small-size/2 $small-size/2 0 $small-size/2;
      flex: 1 1 auto;
    }
  }
}
