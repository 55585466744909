@import 'base/variables';

div#processingPurchaseOverlay {
  display: flex;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: inherit;
    background-color: #404efa;
    opacity: .2;
    z-index: 5;
  }

  h1 {
    margin: 0;
    padding: 5pt;
    border-radius: 20pt;
    text-align: center;
    opacity: 1;
    color: black;
    background-color: $bright-white;
    z-index: 6;
  }
}