@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Lato:400,300italic,300,100italic,100,400italic,700,700italic,900,900italic);
/* line 6, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
html {
  font-family: "Lato", sans-serif, sans-serif;
  font-size: 12pt;
  line-height: 1.6;
  background-color: #5C5453; }

/* line 13, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
body {
  padding: 0;
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

/* line 21, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
input {
  font-family: "Lato", sans-serif;
  font-size: 12pt;
  padding: 2pt 4pt; }

/* line 27, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
section.info, section.warn, section.alert {
  font-size: 18pt; }

/* line 31, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
section.info {
  color: #22aa44; }

/* line 35, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
section.warn {
  color: #aa9922; }

/* line 39, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
section.alert {
  color: #aa4422; }

/* line 43, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
section.actionStatus {
  font-size: 130%; }
  /* line 46, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  section.actionStatus.processing {
    color: #404efa; }
  /* line 49, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  section.actionStatus.complete {
    color: #00da00; }
  /* line 52, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  section.actionStatus.failed {
    color: #FF312E; }

/* line 57, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
div.question {
  border: #a9a09f solid 2px;
  border-radius: 5px;
  padding: 5px; }

/* line 63, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
button, a[role=button] {
  background-color: white;
  padding: 2pt 8pt;
  font-size: 10pt;
  text-decoration: none;
  color: black;
  border: 1px solid #888;
  border-radius: 3pt;
  margin: auto 14pt;
  font-family: "Lato", sans-serif; }
  /* line 74, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  button.dangerous, a[role=button].dangerous {
    background-color: #ff5a5d;
    font-weight: 500; }
  /* line 79, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  button:disabled, a[role=button]:disabled {
    border-color: #EEEEEE;
    color: #EEEEEE; }

/* line 85, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
table {
  width: 100%;
  border: 2px solid #AAA;
  border-collapse: collapse; }
  /* line 91, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  table thead tr:last-child {
    border-bottom: 1px solid #AAA; }
  /* line 95, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  table thead th {
    background-color: #EEE; }
  /* line 103, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  table tbody tr:nth-of-type(even) {
    background-color: #F8F8F8; }
    /* line 106, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
    table tbody tr:nth-of-type(even) th {
      background-color: #EEEEEE; }
  /* line 111, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  table tbody tr th {
    background-color: #F2F2F2;
    text-align: right; }
  /* line 120, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  table tfoot tr {
    border-top: 1px solid #AAA;
    background-color: #EEE; }
    /* line 124, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
    table tfoot tr th {
      text-align: left; }
  /* line 130, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  table th, table td {
    padding: 0.5em 0.5em 0.5em 0.5em; }

/* line 135, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
blockquote {
  margin: 8pt 0;
  padding: 6pt 12pt;
  background-color: #f9f8ef; }

/* line 141, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
section.main {
  background-color: #FFFFFF;
  padding: 0 12pt 0pt 12pt;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1; }
  /* line 150, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  section.main footer {
    text-align: right; }
  /* line 154, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  section.main h1 {
    width: 100%;
    font-size: 12pt;
    box-sizing: border-box;
    color: #FFFFFF;
    background: linear-gradient(to right, #888888, #f8f8f8);
    margin: 0 0 6pt 0;
    padding: 3pt 12pt;
    border-bottom-left-radius: 12pt; }

/* line 166, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
body > footer {
  background-color: #5C5453;
  color: #FFFFFF;
  font-size: 9pt;
  font-weight: 300;
  padding: 0pt 12pt 0pt 12pt;
  border-top: 3pt solid #C3B259; }

/* line 175, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
h1, h2, h3, h4, h5 {
  color: #5C5453;
  font-weight: normal;
  margin: 0; }

/* line 180, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
h1 {
  font-size: 200%; }

/* line 181, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
h2 {
  font-size: 150%; }

/* line 182, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
h3 {
  font-size: 120%; }

/* line 183, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
h4 {
  font-size: 110%; }

/* line 184, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
h5 {
  font-size: 100%; }

/* line 186, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
form {
  font-size: 12pt; }
  /* line 187, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  form fieldset {
    border: 1px solid #d9d5d5;
    border-radius: 1em;
    padding: 10pt 18pt 18pt;
    margin: 12pt 0; }
    /* line 194, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
    form fieldset legend {
      color: #a9a09f; }
  /* line 199, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  form ol {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative; }
    /* line 205, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
    form ol li {
      position: relative;
      margin-left: 1.75em; }
      /* line 208, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
      form ol li h2 {
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        background-color: #a9a09f;
        color: #FFFFFF;
        font-size: 12pt;
        margin: 0.75em 0 0 20px;
        padding: 0.1em 1em; }
        /* line 216, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
        form ol li h2 span.label {
          color: #5C5453; }
        /* line 219, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
        form ol li h2 span.title {
          margin-left: 1em; }
  /* line 228, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  form .two-list-sorter {
    display: flex;
    align-items: center; }
    /* line 232, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
    form .two-list-sorter .list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center; }
      /* line 238, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
      form .two-list-sorter .list select {
        min-width: 15em; }
    /* line 243, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
    form .two-list-sorter .buttons {
      display: flex;
      flex-direction: column;
      align-items: center; }
      /* line 248, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
      form .two-list-sorter .buttons button {
        margin: 0.5em 0.5em 0.5em 0.5em;
        vertical-align: middle; }
  /* line 255, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  form .actions {
    text-align: right; }
    /* line 257, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
    form .actions .reposition {
      display: inline-block; }

@media only screen and (max-width: 500pt) {
  /* line 264, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/base/styles.scss */
  section.main {
    padding: 0 2pt 0pt 2pt; } }

/* line 3, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
section#coaches, section#dashboard, section#available-questionnaires, section#profile {
  flex-direction: column; }

/* line 7, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
section.main {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  height: 90vh; }
  /* line 13, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
  section.main section.screen {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 80vh; }
    /* line 18, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
    section.main section.screen .dashboard {
      flex: 1 1 0; }
    /* line 22, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
    section.main section.screen .screen-item {
      display: flex;
      align-items: stretch;
      flex-direction: column;
      overflow-y: scroll;
      padding: 10pt;
      margin: 1pt; }
      /* line 23, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
      section.main section.screen .screen-item ul, section.main section.screen .screen-item ol {
        margin: 0;
        padding: 0;
        list-style-type: none; }
      /* line 32, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
      section.main section.screen .screen-item details {
        padding: 10pt;
        word-wrap: break-word;
        cursor: pointer; }
        /* line 37, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
        section.main section.screen .screen-item details summary {
          border-bottom: 1pt solid #EEEEEE;
          padding: 3pt; }
          /* line 40, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
          section.main section.screen .screen-item details summary button {
            margin: 0 0 3pt 3pt;
            font-size: 10pt;
            padding: 1pt 8pt; }

/* line 51, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
ul.details-list {
  display: flex;
  flex-direction: column;
  border-radius: 0 0 6pt 6pt;
  background-color: #eee; }
  /* line 56, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
  ul.details-list ul, ul.details-list ol {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  /* line 58, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
  ul.details-list li {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    padding: 2pt 6pt;
    margin: 6pt 0;
    cursor: pointer; }
    /* line 66, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
    ul.details-list li.description, ul.details-list li.actions {
      margin: 0;
      padding: 0; }
    /* line 70, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
    ul.details-list li.actions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;
      align-content: flex-start; }
      /* line 76, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
      ul.details-list li.actions button {
        margin: 0 0 3pt 3pt;
        font-size: 10pt;
        padding: 1pt 8pt; }
    /* line 84, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
    ul.details-list li ol.overview {
      display: flex;
      flex-direction: column;
      border-bottom: 1pt solid #888888; }
    /* line 90, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
    ul.details-list li ol.details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 80%; }
      /* line 95, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
      ul.details-list li ol.details li {
        margin: 0 4pt;
        padding: 0;
        flex: 1 1 0;
        font-weight: bold; }
        /* line 100, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
        ul.details-list li ol.details li label {
          cursor: pointer;
          font-weight: normal; }
        /* line 104, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
        ul.details-list li ol.details li span.statusAccepted {
          color: #00da00; }
        /* line 107, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
        ul.details-list li ol.details li span.statusOpen {
          color: #7f54ff; }

/* line 115, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
ul.group {
  border-radius: 0;
  background-color: #f8f8f8;
  justify-content: space-between; }
  /* line 119, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
  ul.group li {
    border-radius: 6pt;
    background-color: #eee;
    width: 150pt; }

@media screen and (max-width: 800px) {
  /* line 127, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
  html {
    background-color: #f8f8f8; }
  /* line 130, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
  body {
    width: auto; }
  /* line 133, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
  footer {
    display: none; }
  /* line 136, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
  section.main {
    padding: 0; }
    /* line 137, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
    section.main h1 {
      margin: 0; }
    /* line 141, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
    section.main section.screen {
      flex-direction: column; }
      /* line 144, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
      section.main section.screen .screen-item {
        height: 50vh;
        padding: 0;
        border-bottom: 1pt solid #888888;
        margin: 0; }
        /* line 150, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
        section.main section.screen .screen-item ul.details-list li {
          padding: 0; }
        /* line 155, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
        section.main section.screen .screen-item ol.details li {
          margin: 0 2pt; }
        /* line 160, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
        section.main section.screen .screen-item details ::-webkit-details-marker {
          font-size: 4vh;
          padding-right: 10pt; }
        /* line 164, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
        section.main section.screen .screen-item details ul, section.main section.screen .screen-item details ol {
          word-wrap: break-word; }
      /* line 170, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens.scss */
      section.main section.screen .dashboard {
        height: 29vh; } }

/* line 1, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens/home.scss */
section#home {
  flex-direction: column;
  justify-content: center;
  align-items: center; }

/* line 2, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/screens/profile.scss */
section#profile form > fieldset {
  display: flex;
  flex-direction: column; }

/* line 1, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/login.scss */
section#login {
  display: flex;
  flex-direction: column;
  align-items: center; }
  /* line 6, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/login.scss */
  section#login form {
    max-width: 300pt; }
    /* line 8, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/login.scss */
    section#login form fieldset {
      display: flex;
      flex-direction: column; }
      /* line 11, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/login.scss */
      section#login form fieldset label {
        margin-top: 4pt; }
      /* line 14, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/login.scss */
      section#login form fieldset section.actions {
        margin: 8pt; }

/* line 4, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/purchasableQuestionnaires.scss */
table.purchasableQuestionnaires td.description {
  overflow: hidden;
  width: 30%; }

/* line 9, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/purchasableQuestionnaires.scss */
table.purchasableQuestionnaires button {
  background-color: #4bda43; }

/* line 2, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/questionnaireInstancesList.scss */
.questionnaireInstancesList th {
  /* These rules cannot be grouped */ }
  /* line 4, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/questionnaireInstancesList.scss */
  .questionnaireInstancesList th ::-webkit-input-placeholder {
    color: black;
    opacity: 1; }
  /* line 8, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/questionnaireInstancesList.scss */
  .questionnaireInstancesList th ::-moz-placeholder {
    color: black;
    opacity: 1; }
  /* line 12, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/questionnaireInstancesList.scss */
  .questionnaireInstancesList th :-moz-placeholder {
    color: black;
    opacity: 1; }
  /* line 16, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/questionnaireInstancesList.scss */
  .questionnaireInstancesList th :-ms-input-placeholder {
    color: black;
    opacity: 1; }
  /* line 21, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/questionnaireInstancesList.scss */
  .questionnaireInstancesList th p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 10pt; }
  /* line 28, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/questionnaireInstancesList.scss */
  .questionnaireInstancesList th span {
    cursor: pointer; }
    /* line 31, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/client/questionnaireInstancesList.scss */
    .questionnaireInstancesList th span:hover {
      color: cornflowerblue; }

/* line 3, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
header.site {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #5C5453;
  height: 6vh;
  box-shadow: #a9a09f 4pt 4pt 12pt;
  padding: 6pt; }
  /* line 12, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
  header.site h1 {
    flex-grow: 1;
    height: 5vh;
    background: url(/images/logo.svg) left center no-repeat;
    margin: 0 0 0 12pt;
    padding: 0;
    color: #FFFFFF;
    font-size: 4vh;
    font-weight: 300;
    text-transform: uppercase;
    text-shadow: 1pt 1pt 2pt #0c0b0a;
    text-decoration: inherit;
    overflow-x: hidden; }
  /* line 28, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
  header.site nav ol {
    margin: 0 12pt 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end; }
    /* line 35, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
    header.site nav ol li {
      margin-left: 12pt;
      font-size: 12pt; }
      /* line 38, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
      header.site nav ol li a {
        cursor: pointer;
        color: #C3B259;
        text-decoration: none;
        text-shadow: 1pt 1pt 2pt #0c0b0a; }

@media only screen and (max-width: 750px) {
  /* line 51, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
  header.site h1 {
    display: flex;
    flex-grow: 1;
    font-size: 6vw; }
  /* line 57, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
  header.site nav {
    position: relative;
    cursor: pointer;
    line-height: 4vh; }
    /* line 62, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
    header.site nav::before {
      content: "☰";
      color: #FFFFFF;
      margin-right: 12pt;
      font-size: 4vh; }
    /* line 69, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
    header.site nav ol {
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;
      background-color: #5C5453;
      display: none;
      flex-direction: column; }
      /* line 79, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
      header.site nav ol li {
        padding: 15px 20px 15px 15px;
        text-align: left;
        z-index: 1; }
    /* line 87, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/components/navbar.scss */
    header.site nav:hover ol, header.site nav:focus ol {
      display: block; } }

/* line 3, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/processingPurchaseOverlay.scss */
div#processingPurchaseOverlay {
  display: flex;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  justify-content: center;
  align-items: center; }
  /* line 15, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/processingPurchaseOverlay.scss */
  div#processingPurchaseOverlay div {
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: inherit;
    background-color: #404efa;
    opacity: .2;
    z-index: 5; }
  /* line 27, /home/jenkins/jenkins/workspace/Client UI Production/app/styles/processingPurchaseOverlay.scss */
  div#processingPurchaseOverlay h1 {
    margin: 0;
    padding: 5pt;
    border-radius: 20pt;
    text-align: center;
    opacity: 1;
    color: black;
    background-color: #FFFFFF;
    z-index: 6; }
