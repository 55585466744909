.questionnaireInstancesList {
  th {
    /* These rules cannot be grouped */
    ::-webkit-input-placeholder {
      color: black;
      opacity: 1;
    }
    ::-moz-placeholder {
      color: black;
      opacity: 1;
    }
    :-moz-placeholder {
      color: black;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: black;
      opacity: 1;
    }

    p {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 10pt;
    }

    span {
      cursor: pointer;

      &:hover {
        color: cornflowerblue;
      }
    }
  }
}